import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Parallax = ({ sayfaModulId }) => {
    const [modulParallax, setModulParallax] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchModulParallax = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get(`https://localhost:7184/api/modulparallax?sayfaModulId=${sayfaModulId}`);
                setModulParallax(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        if (sayfaModulId) {
            fetchModulParallax();
        }
    }, [sayfaModulId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (modulParallax.length === 0) {
        return <p>Veri yok</p>;
    }

    return (
        <div>
            {modulParallax.map((item) => (
                <div className="pageDetailContent parallax-section" id={`parallax-section-${item.id}`} style={{ backgroundColor: '#ffffff',  backgroundImage: item.resim && item.resim.resimUrl
                    ? `url(/images/${item.resim.resimUrl})`
                    : `url(default-image-url.jpg)` }} key={item.id}>
                    <div className="container text-start">
                        <div className="rg-wrapper">
                            <div className="rgw">
                                <div className="parallax-title">
                                    <h3 className="weta-parallax-tag">
                                        {item.baslik}
                                    </h3>
                                </div>
                                <div className="parallax-description">
                                    <p>{item.icerik}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

};

export default Parallax;
