import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
    const [sayfalar, setSayfalar] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSayfalar = async () => {
            console.log('API çağrısı yapılıyor...');
            try {
                const response = await axios.get('https://localhost:7184/api/navbar');
                console.log('Veri çekildi:', response.data);
                setSayfalar(response.data);
            } catch (error) {
                console.error('Veri çekme hatası:', error);
            }
        };
    
        fetchSayfalar();
    }, []);
    

    const handleHomeClick = (e) => {
        e.preventDefault();
        navigate('/');
    };

    return (
        <div className="section-header">
            <div className="container" id="type-1">
                <div className="row">
                    <div className="col-6 col-lg-3">
                        <div className="logo-container">
                            <div className="logo">
                                {/* Logo alanı */}
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-lg-9">
                        <div className="navbar-weta justify-content-between">
                            <div className="full-width">
                                <div className="menu-click">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>

                                <div className="hidden" id="navigation">
                                    <ul className="navbar">
                                        {/* Anasayfa için özel Link */}
                                        <li>
                                            <a href="/" onClick={handleHomeClick}>
                                                Anasayfa
                                            </a>
                                        </li>
                                        {/* Diğer sayfalar, "Anasayfa"yı hariç tut */}
                                        {sayfalar
                                            .filter(sayfa => sayfa.adi.toLowerCase() !== 'anasayfa')
                                            .map(sayfa => (
                                                <li key={sayfa.id}>
                                                    <Link to={`/${sayfa.url}`}>
                                                        {sayfa.adi}
                                                    </Link>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
