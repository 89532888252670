import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AdminLayout from '../layouts/AdminLayout';

const FormYonetimi = () => {
    const [kullaniciFormlari, setKullaniciFormlari] = useState([]);

    useEffect(() => {
        const fetchKullaniciFormlari = async () => {
            try {
                const response = await axios.get('https://localhost:7184/api/KullaniciFormu');
                setKullaniciFormlari(response.data);
            } catch (error) {
                console.error('Veri alma hatası:', error);
            }
        };

        fetchKullaniciFormlari();
    }, []);

    return (
        <div>
            <AdminLayout />
            <section className="users-list p-5 forms" id="users-list">
                <div className="container">
                    <div className="rgw-content">
                        <div className="row align-items-center">
                            <div className="col-md-9">
                                <h3>Formlar</h3>
                            </div>
                            <div className="col-md-3">
                            </div>
                        </div>
                        <table className="table table-striped table-borderless">
                            <thead>
                                <tr>
                                    <th>Adı Soyadı</th>
                                    <th>E-Mail</th>
                                    <th>Türü</th>
                                </tr>
                            </thead>
                            <tbody>
                                {kullaniciFormlari.map((form) => (
                                    <tr key={form.id}>
                                        <td>{form.adi} {form.soyadi}</td>
                                        <td>{form.email}</td>
                                        <td className="d-flex justify-content-end">
                                            <Link to={`/admin/form-detay/${form.id}`}>
                                                <i className="fa-solid fa-circle-info"></i>
                                            </Link>
                                            <i className="fa-regular fa-trash-can"></i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default FormYonetimi;
