import React from 'react';
import AdminLayout from '../layouts/AdminLayout'; 

const Home = () => {
    return (
        <div>
            <AdminLayout></AdminLayout>
            <h1>Admin Home</h1>
           
        </div>
    );
};

export default Home;
