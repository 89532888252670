// components/ContactForm.jsx
import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        Adi: '',
        Soyadi: '',
        Email: '',
        Telefon: '',
        Mesaj: ''
    });

    const [status, setStatus] = useState({
        loading: false,
        success: null,
        error: null,
        errorDetails: []
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Submitting form data:', formData); // Gönderilen veriyi kontrol edin

        setStatus({ loading: true, success: null, error: null, errorDetails: [] });

        try {
            const response = await axios.post('https://localhost:7184/api/KullaniciFormu', formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
                // withCredentials: true // Eğer gerekli ise
            });
            setStatus({ loading: false, success: response.data.message, error: null, errorDetails: [] });
            setFormData({
                Adi: '',
                Soyadi: '',
                Email: '',
                Telefon: '',
                Mesaj: ''
            });
        } catch (error) {
            console.error('Form submission error:', error.response); // Hata detaylarını konsola yazdırın
            let errorMessage = 'Form gönderilirken bir hata oluştu.';
            let errorDetails = [];

            if (error.response) {
                if (error.response.data && error.response.data.errors) {
                    errorDetails = error.response.data.errors;
                } else if (error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
            }

            setStatus({ loading: false, success: null, error: errorMessage, errorDetails });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <label className="custom-label" htmlFor="Adi">Adınız</label>
                    <input
                        type="text"
                        name="Adi"
                        className="form-control form-input"
                        value={formData.Adi}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="col-md-6 mb-3">
                    <label className="custom-label" htmlFor="Soyadi">Soyadınız</label>
                    <input
                        type="text"
                        name="Soyadi"
                        className="form-control form-input"
                        value={formData.Soyadi}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="col-md-6 mb-3">
                    <label className="custom-label" htmlFor="Email">E-Posta Adresiniz</label>
                    <input
                        type="email"
                        name="Email"
                        className="form-control form-input"
                        value={formData.Email}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="col-md-6 mb-3" id="phonenumber">
                    <label className="custom-label" htmlFor="Telefon">Telefon Numaranız</label>
                    <input
                        type="text"
                        name="Telefon"
                        className="form-control form-input"
                        value={formData.Telefon}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="col-md-12 mb-3">
                    <label className="custom-label" htmlFor="Mesaj">Mesajınız</label>
                    <textarea
                        name="Mesaj"
                        rows="5"
                        className="form-control form-input"
                        value={formData.Mesaj}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>

                <div className="col-md-12 mb-3 form-button">
                    <button type="submit" id="submitButton" className="btn btn-color" disabled={status.loading}>
                        {status.loading ? 'Gönderiliyor...' : 'Gönder'}
                    </button>
                </div>

                {status.success && <div className="alert alert-success">{status.success}</div>}
                {status.error && (
                    <div className="alert alert-danger">
                        {status.error}
                        {status.errorDetails.length > 0 && (
                            <ul>
                                {status.errorDetails.map((detail, index) => (
                                    <li key={index}>{detail}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                )}
            </div>
        </form>
    );
};

export default ContactForm;
