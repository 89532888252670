import React, { createContext, useContext, useState, useEffect } from 'react';

// AuthContext'i oluşturun
export const AuthContext = createContext();

// useAuth hook'u ile AuthContext'i kolayca kullanın
export const useAuth = () => {
  return useContext(AuthContext);
};

// AuthProvider ile giriş durumu ve işlemlerini yönetin
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return sessionStorage.getItem('isAuthenticated') === 'true';
  });
  const [currentUser, setCurrentUser] = useState(null); // Kullanıcı bilgilerini saklamak için

  const login = (user) => {
    setIsAuthenticated(true);
    setCurrentUser(user); // Kullanıcı bilgilerini güncelle
    sessionStorage.setItem('isAuthenticated', 'true');
  };

  const logout = () => {
    setIsAuthenticated(false);
    setCurrentUser(null); // Kullanıcı bilgilerini sıfırla
    sessionStorage.removeItem('isAuthenticated');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
