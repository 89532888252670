import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // ../ ile doğru dizinden içe aktar

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth(); // useAuth hook'unu kullanarak giriş durumu alın

  if (!isAuthenticated) {
    return <Navigate to="/login" />; // Giriş yapılmamışsa login sayfasına yönlendir
  }

  return children;
};

export default ProtectedRoute;
