import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import AdminLayout from '../layouts/AdminLayout';

const FormDetay = () => {
    const { id } = useParams();
    const [formDetay, setFormDetay] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFormDetay = async () => {
            try {
                // API çağrısını güncelledik
                const response = await axios.get(`https://localhost:7184/api/KullaniciFormu/${id}`);
                setFormDetay(response.data);
            } catch (err) {
                // Hata mesajını güncelledik
                if (err.response && err.response.data) {
                    setError(err.response.data.title || 'Veri yüklenirken bir hata oluştu.');
                } else {
                    setError('Veri yüklenirken bir hata oluştu.');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchFormDetay();
    }, [id]);

    if (loading) {
        return <p>Yükleniyor...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (!formDetay) {
        return <p>Form bilgileri bulunamadı.</p>;
    }

    return (
        <div>
            <AdminLayout />
            <div className='users-list p-5 forms' id="users-list">
                <div className="rgw-content">
                    <div className='container'>
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h3>Form Detayı</h3>
                            </div>
                            <div className="col-md-6 text-end">
                                <p className="m-0">
                                    <span className="modal-content-title">Tarih:</span> {new Date(formDetay.gonderimTarihi).toLocaleDateString()} /
                                    <span className="modal-content-title">Saat:</span> {new Date(formDetay.gonderimTarihi).toLocaleTimeString()}
                                </p>
                            </div>
                        </div>

                        <div className="user-add-form mt-4">
                            <div className="row">
                                <div className="col-md-6 mb-4">
                                    <span className="modal-content-title">Adı Soyadı</span>
                                    <p>{formDetay.adi} {formDetay.soyadi}</p>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <span className="modal-content-title">Telefon Numarası</span>
                                    <p>{formDetay.telefon}</p>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <span className="modal-content-title">E-Mail</span>
                                    <p>{formDetay.email}</p>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <span className="modal-content-title">Mesaj</span>
                                    <p>{formDetay.mesaj}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormDetay;  
