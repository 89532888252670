import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Gallery = ({ sayfaModulId }) => {
    const [modulGallery, setModulGallery] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchModulGallery = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get(`https://localhost:7184/api/modulgallery?sayfaModulId=${sayfaModulId}`);
                setModulGallery(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        if (sayfaModulId) {
            fetchModulGallery();
        }
    }, [sayfaModulId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (modulGallery.length === 0) {
        return <p>Veri yok</p>;
    }

    return (
        <div>
            <div id="textImgContent-8">
                {modulGallery.map((item) => (
                    <div className="pageDetailContent"key={item.id} id={`page-detail-section-${item.id}`} style={{ backgroundColor: '#ffffff' }}>
                        <div className="container">
                            <div className="row" id="privatePageContentItem_">
                                <div className="col-md-5 rexa-content">
                                    <h3 className="weta-icerik-tag">{item.baslik}</h3>
                                    <div className="weta-description">
                                        <p><span style={{ fontSize: '18px' }}>{item.icerik}</span></p>
                                    </div>
                                </div>
    
                                <div className="col-md-7 rexa-gorseller">
                                    <div className="rg-wrapper">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
    
};

export default Gallery;
