import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const AdminNavbar = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout(); // Kullanıcıyı çıkış yap
        navigate('/login'); // Login sayfasına yönlendir
    };

    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary bg-dark" data-bs-theme="dark">
            <div className="container">
                <a className="navbar-brand" href="#">Moonlight Panel</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to="/admin/Home" className="nav-link">Ana Sayfa</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/admin/SayfaList" className="nav-link">Sayfalar</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/admin/FormYonetimi" className="nav-link">Form Yonetimi</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link" onClick={handleLogout} style={{ cursor: 'pointer' }}>Çıkış</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default AdminNavbar;
