import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const { login, currentUser } = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // Eğer kullanıcı zaten giriş yapmışsa yönlendir
  useEffect(() => {
    if (currentUser) {
      navigate('/admin/Home');
    }
  }, [currentUser, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    const body = new URLSearchParams({
      KullaniciAdi: username,
      Sifre: password,
    }).toString();

    try {
      const response = await axios.post(`https://localhost:7184/api/Login`, body, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });

      const user = response.data; 
      login(user); // Kullanıcı bilgilerini geç
      navigate('/admin/SayfaList');
    } catch (err) {
      const message = err.response?.data?.message || 'Kullanıcı adı veya şifre yanlış.';
      setError(message);
    }
  };

  return (
    <div>
      <h1>Login Sayfası</h1>
      <form onSubmit={handleLogin}>
        <div>
          <label>Kullanıcı Adı:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Şifre:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={!username || !password}>Giriş Yap</button>
        {error && <p className="text-danger">{error}</p>}
      </form>
    </div>
  );
};

export default Login;
