import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ResimYazi = ({ sayfaModulId }) => {
    const [modulResimYazi, setModulResimYazi] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchModulResimYazi = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get(`https://localhost:7184/api/modulresimyazi?sayfaModulId=${sayfaModulId}`);
                setModulResimYazi(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        if (sayfaModulId) {
            fetchModulResimYazi();
        }
    }, [sayfaModulId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (modulResimYazi.length === 0) {
        return <p>Veri yok</p>;
    }

    return (
        <div>
            <div id="textImgContent-8">
                {modulResimYazi.map((item) => (
                    <div className="pageDetailContent" id={`page-detail-section-${item.id}`} style={{ backgroundColor: '#ffffff' }} key={item.id}>
                        <div className="container">
                            <div className="row" id={`privatePageContentItem_${item.id}`}>
                                <div className="col-md-5 rexa-content">
                                    <h3 className="weta-icerik-tag">{item.baslik}</h3>
                                    <div className="weta-description">
                                        <p><span style={{ fontSize: '18px' }}>{item.icerik}</span></p>
                                    </div>
                                </div>
    
                                <div className="col-md-7 rexa-gorseller">
                                    <div className="rg-wrapper">
                                        {item.resim && item.resim.resimUrl && (
                                            <div className="rgw">
                                                <img
                                                    src={`/images/${item.resim.resimUrl}`}
                                                    title={item.baslik}
                                                    style={{ width: '100%', height: 'auto' }}
                                                    onError={(e) => {
                                                        e.target.onerror = null; 
                                                        e.target.src = "default-image-url.jpg"; 
                                                    }} 
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
    
};

export default ResimYazi;
