import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Html = ({ sayfaModulId }) => {
    const [modulHtml, setModulHtml] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchModulHtml = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get(`https://localhost:7184/api/modulhtml?sayfaModulId=${sayfaModulId}`);
                setModulHtml(response.data);
            } catch (err) {
                console.error('Error fetching modul html:', err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        if (sayfaModulId) {
            fetchModulHtml();
        }
    }, [sayfaModulId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!Array.isArray(modulHtml) || modulHtml.length === 0) {
        return <p>Veri yok</p>;
    }

    return (
        <div>
            {modulHtml.map((item) => (
                <div className="pageDetailContent htmlSection" id={`html-section-${item.id}`} key={item.id}>
                        {item.icerik}
                </div>
            ))}
        </div>
    );
};

export default Html;
