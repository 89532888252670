import React from 'react';

const Slider = ({ data }) => {
    if (!data) {
        return <div>Data is not available</div>;
    }

    return (
        <div>
            <h5>{data.id}</h5>
            <p>{data.baslik}</p>
            <p>{data.icerik}</p>
        </div>
    );
};

export default Slider;
