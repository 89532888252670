import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Home from './admin/Home';
import SayfaList from './admin/SayfaList';
import FormYonetimi from './admin/FormYonetimi';
import FormDetay from './admin/FormDetay';
import Login from './admin/Login';
import { AuthProvider } from './context/AuthContext';
import NotFound from './components/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './layouts/Navbar';
import Page from './components/Page';
import axios from 'axios';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Content />
      </Router>
    </AuthProvider>
  );
};

const Content = () => {
  const location = useLocation();
  const isAdminRoute = location.pathname.toLowerCase().startsWith('/admin');
  const isLoginRoute = location.pathname.toLowerCase() === '/login';
  
  const [sayfaBilesenleri, setSayfaBilesenleri] = useState([]);

  useEffect(() => {
    const fetchSayfalar = async () => {
      try {
        const response = await axios.get('https://localhost:7184/api/navbar');
        setSayfaBilesenleri(response.data);
      } catch (error) {
        console.error('Sayfalar yüklenirken hata:', error);
      }
    };

    fetchSayfalar();
  }, []);

  return (
    <>
      {!(isAdminRoute || isLoginRoute) && <Navbar />}
      <Routes>
        <Route path="/login" element={<Login />} />
        
        <Route path="/admin/Home" element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        } />
        
        <Route path="/admin/SayfaList" element={
          <ProtectedRoute>
            <SayfaList />
          </ProtectedRoute>
        } />
        
        <Route path="/admin/FormYonetimi" element={
          <ProtectedRoute>
            <FormYonetimi />
          </ProtectedRoute>
        } />
        
        {/* Form detay sayfası için rota */}
        <Route path="/admin/form-detay/:id" element={
          <ProtectedRoute>
            <FormDetay />
          </ProtectedRoute>
        } />

        <Route path="/admin" element={<Navigate to="/admin/Home" />} />
        <Route path="*" element={<NotFound />} />
        
        {/* Anasayfa için Page bileşenini kullanın */}
        <Route path="/" element={<Page />} />
        <Route path="/:urlName" element={<Page />} />
      </Routes>
    </>
  );
};

export default App;
