import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Paragraf = ({ sayfaModulId }) => {
    const [modulParagraf, setModulParagraf] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchModulParagraf = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get(`https://localhost:7184/api/modulparagraf?sayfaModulId=${sayfaModulId}`);
                setModulParagraf(response.data); 
            } catch (err) {
                console.error('Error fetching modul paragraf:', err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        if (sayfaModulId) {
            fetchModulParagraf();
        }
    }, [sayfaModulId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!Array.isArray(modulParagraf) || modulParagraf.length === 0) {
        return <p>Veri yok</p>;
    }

    return (
        <div>
            {modulParagraf.map((item) => (
                <div className="pageDetailContent paragraphSection" id={`paragraph-section-${item.id}`} key={item.id}>
                    <div className="container">
                        <div className="col-md-12">
                            <div className="paragraph-title">
                                <h3 className="weta-parallax-tag">
                                    {item.baslik}
                                </h3>
                            </div>
                            <div className="paragraph-content">
                                <p>{item.icerik}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Paragraf;
