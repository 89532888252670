import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button, Spinner } from 'react-bootstrap';
import AdminLayout from '../layouts/AdminLayout';
import ResimYazi from '../components/ResimYazi';
import Slider from '../components/Slider';
import Parallax from '../components/Parallax';
import Gallery from '../components/Gallery';
import Iletisim from '../components/Iletisim';
import Paragraf from '../components/Paragraf';
import Html from '../components/Html';
import Style from '../admincss/admin.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import 'react-quill/dist/quill.snow.css';

const SayfaList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activePage, setActivePage] = useState(null);
    const [activePageContent, setActivePageContent] = useState([]);
    const searchParams = new URLSearchParams(location.search);
    const sayfaId = searchParams.get('sayfaId');
    const [sayfalar, setSayfalar] = useState([]);
    const [moduller, setModuller] = useState([]);
    const [selectedSayfa, setSelectedSayfa] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showImageTextModal, setShowImageTextModal] = useState(false);
    const [imageTextData, setImageTextData] = useState(null);
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const [GalleryData, setGalleryData] = useState(null);
    const [activeTab, setActiveTab] = useState('gallery');
    const [imageFile, setImageFile] = useState(null);
    const [galleryImage, setGalleryImage] = useState({ ModulGalleryId: '' });
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [galleryImages, setGalleryImages] = useState([]);
    const [modulGalleryId, setModulGalleryId] = useState(null);
    const [ParallaxData, setParallaxData] = useState(null);
    const [showParallaxModal, setShowParallaxModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sayfaModul, setSayfaModul] = useState([]);
    const [IletisimData, setIletisimData] = useState(null);
    const [showIletisimModal, setShowIletisimModal] = useState(false);
    const [ParagrafData, setParagrafData] = useState(null);
    const [showParagrafModal, setShowParagrafModal] = useState(false);
    const [HtmlData, setHtmlData] = useState(null);
    const [showHtmlModal, setShowHtmlModal] = useState(false);

    const handleShowAddModal = () => {
        setSelectedSayfa({ adi: '', url: '', description: '' });
        setShowAddModal(true);
    };
    const handleShowEditModal = (sayfa) => {
        setSelectedSayfa(sayfa);
        setShowEditModal(true);
    };
    const handleClose = () => {
        setActiveTab('gallery');
        setShowAddModal(false);
        setShowEditModal(false);
        setShowImageTextModal(false);
        setShowParallaxModal(false);
        setShowGalleryModal(false);
        setSelectedSayfa(null);
        setImageTextData(null);
        setParallaxData(null);
        setShowIletisimModal(false);
        setIletisimData(null);
        setShowHtmlModal(false);
        setHtmlData(null);
        setGalleryData({});
        setGalleryImages([]);
        setFiles([]);
        setShowParagrafModal(false);
        setParagrafData(null);
    };
    const turkishToLowerCase = (str) => {
        return str
            .replace(/Ü/g, 'u')
            .replace(/ü/g, 'u')
            .replace(/İ/g, 'i')
            .replace(/ı/g, 'i')
            .replace(/Ş/g, 's')
            .replace(/ş/g, 's')
            .replace(/Ö/g, 'o')
            .replace(/ö/g, 'o')
            .replace(/Ç/g, 'c')
            .replace(/ç/g, 'c')
            .replace(/Ğ/g, 'g')
            .replace(/ğ/g, 'g')
            .toLowerCase();
    };
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'adi') {
            if (!selectedSayfa.id) {
                const formattedUrl = value
                    .toLowerCase()
                    .replace(/\s+/g, '-')
                    .replace(/[^a-z0-9-]/g, '');

                setSelectedSayfa((prev) => ({
                    ...prev,
                    adi: value,
                    title: value,
                    url: formattedUrl
                }));
            } else {
                setSelectedSayfa((prev) => ({
                    ...prev,
                    adi: value
                }));
            }
        } else {
            setSelectedSayfa((prev) => ({ ...prev, [name]: value }));
        }
    };
    const handleSave = async () => {
        if (!selectedSayfa || !selectedSayfa.adi) {
            console.error('Geçersiz model: ', selectedSayfa);
            return;
        }

        const title = selectedSayfa.adi;

        let formattedUrl;

        if (selectedSayfa.id) {
            formattedUrl = selectedSayfa.url;
        } else {
            formattedUrl = turkishToLowerCase(selectedSayfa.adi)
                .replace(/\s+/g, '-')
                .replace(/[^a-z0-9-]/g, '');
        }

        const sayfaToSave = {
            ...selectedSayfa,
            title: title,
            url: formattedUrl,
            aktif: selectedSayfa.aktif,
        };

        try {
            let response;

            if (selectedSayfa.id) {
                response = await axios.post('https://localhost:7184/api/sayfaDuzenle', sayfaToSave, {
                    headers: { 'Content-Type': 'application/json' }
                });

                setSayfalar((prevSayfalar) =>
                    prevSayfalar.map((sayfa) =>
                        sayfa.id === selectedSayfa.id ? sayfaToSave : sayfa
                    )
                );
            } else {
                response = await axios.post('https://localhost:7184/api/sayfaEkle', sayfaToSave, {
                    headers: { 'Content-Type': 'application/json' }
                });

                setSayfalar((prevSayfalar) => [...prevSayfalar, { ...sayfaToSave, id: response.data.id }]);
            }

            handleClose();
            fetchData();
        } catch (error) {
            console.error('İşlem sırasında hata:', error.response ? error.response.data : error.message);
        }
    };
    const handleAddModule = async (modulId) => {
        try {
            const response = await axios.post(`https://localhost:7184/api/SayfaModelEkle?sayfaId=${sayfaId || 0}&modulId=${modulId}`);
            console.log(response.data.message);
            fetchData();
        } catch (error) {
            console.error('Modül eklenirken hata:', error.response ? error.response.data : error.message);
        }
    };
    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(`https://localhost:7184/api/sayfasil?Id=${id}`);
            console.log(response.data.message);
            await fetchData();
        } catch (error) {
            console.error('Silme hatası:', error.response ? error.response.data : error.message);
        }
    };
    const fetchData = async () => {
        setLoading(true);
        try {
            const sayfaResponse = await axios.get(`https://localhost:7184/api/sayfa?sayfaId=${sayfaId || 0}`);
            setSayfalar(sayfaResponse.data.sayfalar);

            const modulResponse = await axios.get('https://localhost:7184/api/moduller');
            setModuller(modulResponse.data);

            if (sayfaId) {
                const pageResponse = await axios.get(`https://localhost:7184/api/aktifSayfa?sayfaId=${sayfaId}`);
                setActivePage(pageResponse.data);

                const contentResponse = await axios.get(`https://localhost:7184/api/pageDataList?sayfaId=${sayfaId}`);
                setActivePageContent(contentResponse.data);
            }
        } catch (error) {
            console.error('Error loading data: ', error);
        } finally {
            setLoading(false);
        }
    };
    const handleShowImageTextModal = async (ResimYaziId) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://localhost:7184/api/ResimYaziGetir?ResimYaziId=${ResimYaziId}`);

            if (response.data) {
                setImageTextData(response.data);
                setShowImageTextModal(true);
            } else {
                console.warn('ResimYazi verisi boş geldi.');
            }
        } catch (error) {
            console.error('ResimYazi verisi alınırken hata:', error.response ? error.response.data : error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleShowParallaxModal = async (parallaxId) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://localhost:7184/api/ParallaxGetir?ParallaxId=${parallaxId}`);

            if (response.data) {
                setParallaxData(response.data);
                setShowParallaxModal(true);
            } else {
                console.warn('Parallax verisi boş geldi.');
            }
        } catch (error) {
            console.error('Parallax verisi alınırken hata:', error.response ? error.response.data : error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleShowIletisimModal = async (iletisimId) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://localhost:7184/api/IletisimGetir?IletisimId=${iletisimId}`);

            if (response.data) {
                setIletisimData(response.data);
                setShowIletisimModal(true);
            } else {
                console.warn('Iletisim verisi boş geldi.');
            }
        } catch (error) {
            console.error('Iletisim verisi alınırken hata:', error.response ? error.response.data : error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleShowParagrafModal = async (paragrafId) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://localhost:7184/api/ParagrafGetir?ParagrafId=${paragrafId}`);

            if (response.data) {
                setParagrafData(response.data);
                setShowParagrafModal(true);
            } else {
                console.warn('Paragraf verisi boş geldi.');
            }
        } catch (error) {
            console.error('Paragraf verisi alınırken hata:', error.response ? error.response.data : error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleShowHtmlModal = async (htmlId) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://localhost:7184/api/HtmlGetir?HtmlId=${htmlId}`);

            if (response.data) {
                setHtmlData(response.data);
                setShowHtmlModal(true);
            } else {
                console.warn('Html verisi boş geldi.');
            }
        } catch (error) {
            console.error('Html verisi alınırken hata:', error.response ? error.response.data : error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleUpdateImageText = async (imageTextData) => {
        if (!imageTextData || !imageTextData.id) {
            console.error('Güncelleme için geçersiz ResimYazi verisi:', imageTextData);
            return;
        }

        console.log('Updating ResimYazi with ID:', imageTextData.id);
        setLoading(true);

        const formData = new FormData();
        formData.append('Id', imageTextData.id);
        formData.append('Baslik', imageTextData.baslik);
        formData.append('Icerik', imageTextData.icerik);
        formData.append('Durum', imageTextData.durum ? 'true' : 'false');
        if (imageTextData.ResimFile) {
            formData.append('ResimFile', imageTextData.ResimFile);
        }

        try {
            const response = await axios.post('https://localhost:7184/api/ResimYaziGuncelle', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                console.log(response.data.message);
                setShowImageTextModal(false);
            }
        } catch (error) {
            console.error('Güncelleme sırasında hata:', error.response ? error.response.data : error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleUpdateParallax = async (ParallaxData) => {
        if (!ParallaxData || !ParallaxData.id) {
            console.error('Güncelleme için geçersiz Parallax verisi:', ParallaxData);
            return;
        }
        setLoading(true);

        const formData = new FormData();
        formData.append('Id', ParallaxData.id);
        formData.append('Baslik', ParallaxData.baslik);
        formData.append('Icerik', ParallaxData.icerik);
        formData.append('Durum', ParallaxData.durum ? 'true' : 'false');

        if (ParallaxData.ResimFile) {
            formData.append('ResimFile', ParallaxData.ResimFile);
        }

        try {
            const response = await axios.post('https://localhost:7184/api/ParallaxGuncelle', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                console.log(response.data.message);
                setShowParallaxModal(false);
            }
        } catch (error) {
            console.error('Güncelleme sırasında hata:', error.response ? error.response.data : error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleUpdateIletisim = async (IletisimData) => {
        if (!IletisimData || !IletisimData.id) {
            console.error('Güncelleme için geçersiz Iletisim verisi:', IletisimData);
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append('Id', IletisimData.id);
        formData.append('Baslik', IletisimData.baslik || '');
        formData.append('Icerik', IletisimData.icerik || '');
        formData.append('FormBaslik', IletisimData.formBaslik || '');
        formData.append('FormIcerik', IletisimData.formIcerik || '');
        formData.append('Durum', IletisimData.durum ? 'true' : 'false');

        try {
            const response = await axios.post('https://localhost:7184/api/IletisimGuncelle', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                console.log(response.data.message);
                setShowIletisimModal(false);
                fetchData();
            }
        } catch (error) {
            console.error('Güncelleme sırasında hata:', error.response ? error.response.data : error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleUpdateParagraf = async (ParagrafData) => {
        if (!ParagrafData || !ParagrafData.id) {
            console.error('Güncelleme için geçersiz Paragraf verisi:', ParagrafData);
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append('Id', ParagrafData.id);
        formData.append('Baslik', ParagrafData.baslik || '');
        formData.append('Icerik', ParagrafData.icerik || '');
        formData.append('Durum', ParagrafData.durum ? 'true' : 'false');

        try {
            const response = await axios.post('https://localhost:7184/api/ParagrafGuncelle', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                console.log(response.data.message);
                setShowParagrafModal(false);
                fetchData();
            }
        } catch (error) {
            console.error('Güncelleme sırasında hata:', error.response ? error.response.data : error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleUpdateHtml = async (HtmlData) => {
        if (!HtmlData || !HtmlData.id) {
            console.error('Güncelleme için geçersiz Html verisi:', HtmlData);
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append('Id', HtmlData.id);
        formData.append('Icerik', HtmlData.icerik || '');
        formData.append('Durum', HtmlData.durum ? 'true' : 'false');

        try {
            const response = await axios.post('https://localhost:7184/api/HtmlGuncelle', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                console.log(response.data.message);
                setShowHtmlModal(false);
                fetchData();
            }
        } catch (error) {
            console.error('Güncelleme sırasında hata:', error.response ? error.response.data : error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleShowGalleryModal = async (galleryId) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://localhost:7184/api/GalleryGetir?GalleryId=${galleryId}`);

            if (response.data) {
                console.log('Fetched data:', response.data);
                setGalleryData(response.data);
                setShowGalleryModal(true);
            } else {
                console.warn('Gallery verisi boş geldi.');
            }
        } catch (error) {
            console.error('Gallery verisi alınırken hata:', error.response ? error.response.data : error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleUpdateGallery = async (galleryData) => {
        if (!galleryData || !galleryData.id) {
            console.error('Güncelleme için geçersiz ResimYazi verisi:', galleryData);
            return;
        }

        console.log('Updating ResimYazi with ID:', galleryData.id);
        setLoading(true);

        try {
            const response = await axios.post('https://localhost:7184/api/GalleryGuncelle', {
                id: galleryData.id,
                baslik: galleryData.baslik,
                icerik: galleryData.icerik,
                durum: galleryData.durum,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                console.log(response.data.message);
                setShowGalleryModal(false);
            }
        } catch (error) {
            console.error('Güncelleme sırasında hata:', error.response ? error.response.data : error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleFileChange = (event) => {
        setFiles(event.target.files);
    };
    const handleImageUpload = async () => {
        const formData = new FormData();

        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
            }
        }

        formData.append('ModulGalleryId', GalleryData.id || '');

        setIsLoading(true);

        try {
            const response = await fetch('https://localhost:7184/api/GalleryResimEkle', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(`Ağ yanıtı iyi değil: ${errorMessage}`);
            }

            const result = await response.json();
            console.log('Başarıyla yüklendi:', result);
        } catch (error) {
            console.error('Yükleme sırasında hata:', error.message);
            alert(`Hata: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };
    const fetchGalleryImages = async (galleryImageId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://localhost:7184/api/GallerImageGetir?GalleryImageId=${galleryImageId}`);
            setGalleryImages(response.data);
        } catch (error) {
            console.error("Hata:", error);
        } finally {
            setIsLoading(false);
        }
    };
    const updateGalleryImages = async () => {
        if (!modulGalleryId) return; // ID yoksa güncelleme yapma

        if (!Array.isArray(galleryImages) || galleryImages.length === 0) {
            console.warn("Güncellenmesi gereken galeri resimleri yok.");
            return;
        }

        try {
            const response = await axios.post('http://localhost:7184/api/GallerImageGuncelle', {
                modulGalleryId,
                models: galleryImages
            });
            console.log("Güncelleme başarılı:", response.data.message);
        } catch (error) {
            if (error.response) {
                console.error("Güncellerken bir hata oluştu:", error.response.data.message);
            } else if (error.request) {
                console.error("İstek yapıldı ama yanıt alınamadı:", error.request);
            } else {
                console.error("Hata oluştu:", error.message);
            }
        }
    };
    const handleDeleteSayfaModul = async (modulId) => {
        if (modulId === undefined) {
            console.error("ModulId is undefined.");
            return;
        }
        console.log("Attempting to delete ModulId:", modulId);
        try {
            const response = await axios.delete(`https://localhost:7184/api/SayfaModulSil?ModulId=${modulId}`);
            if (response.status === 200) {
                setSayfaModul(prevSayfaModul => {
                    console.log("Type of prevSayfaModul:", typeof prevSayfaModul);
                    console.log("Is Array:", Array.isArray(prevSayfaModul));
                    return prevSayfaModul.filter(modul => modul.ModulId !== modulId);
                });
                fetchData();
            }
        } catch (error) {
            console.error('Silme işlemi sırasında hata:', error.response ? error.response.data : error.message);
        }
    };
        
    useEffect(() => {
        setActivePage(null);
        setActivePageContent([]);
        fetchData();
    }, [sayfaId]);

    return (
        <div>
            <AdminLayout />
            <div>
                <h4>Sayfalar</h4>
                {loading ? (
                    <div>Yükleniyor...</div>
                ) : (
                    <div className="row">
                        <div className="col-2">
                            <ul className="list-group">
                                {sayfalar.length > 0 ? (
                                    sayfalar.map((item) => (
                                        <li className="list-group-item" key={item.id}>
                                            <Link to={`/Admin/SayfaList?sayfaId=${item.id}`} className="text-decoration-none text-black fw-bold">
                                                <i className="fa-solid fa-angles-right"></i> {item.adi}
                                            </Link>
                                            <span
                                                className="icon-button"
                                                onClick={() => handleShowEditModal(item)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <i className="fa-solid fa-pencil text-primary"></i>
                                            </span>
                                            <span
                                                className="icon-button"
                                                onClick={() => {
                                                    if (window.confirm("Bu sayfayı silmek istediğinize emin misiniz?")) {
                                                        handleDelete(item.id);
                                                    }
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <i className="fa-solid fa-trash"></i>
                                            </span>
                                        </li>
                                    ))
                                ) : (
                                    <li className="list-group-item">Veri yok</li>
                                )}
                                <li className="list-group-item" onClick={handleShowAddModal}>
                                    <i className="fa-solid fa-plus text-primary"></i> Yeni Sayfa Ekle
                                </li>
                            </ul>
                        </div>
                        <div className="col-8">
                            {activePage ? (
                                <div>
                                    <h1>{activePage.adi}</h1>
                                </div>
                            ) : (
                                <p>Yükleniyor...</p>
                            )}

                            {activePageContent.map((item, index) => {
                                return (
                                    <div className="row mt-4" key={`${item.id}-${index}`} id={`${item.orderId}`}>
                                        <div className="w-100 bg-secondary text-white p-1">
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <span>{item.adi}</span>
                                                </div>
                                                <div className="col-md-3 text-end">
                                                    {item.type === 0 && (
                                                        <span onClick={() => handleShowImageTextModal(item.resimYaziId)}>
                                                            <i className="fa-solid fa-pencil text-light"></i>
                                                        </span>
                                                    )}
                                                    {item.type === 1 && <span></span>}
                                                    {item.type === 2 &&
                                                        <span onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleShowParallaxModal(item.parallaxId);
                                                        }}>
                                                            <i className="fa-solid fa-pencil text-light"></i>
                                                        </span>
                                                    }
                                                    {item.type === 3 &&
                                                        <span onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleShowGalleryModal(item.galleryId);
                                                        }}>
                                                            <i className="fa-solid fa-pencil text-light"></i>
                                                        </span>}
                                                    {item.type === 4 &&
                                                        <span onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleShowIletisimModal(item.iletisimId);
                                                        }}>
                                                            <i className="fa-solid fa-pencil text-light"></i>
                                                        </span>
                                                    }
                                                    {item.type === 5 &&
                                                        <span onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleShowParagrafModal(item.paragrafId)
                                                        }}>
                                                            <i className="fa-solid fa-pencil text-light"></i>
                                                        </span>
                                                    }
                                                    {item.type === 6 &&
                                                        <span onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleShowHtmlModal(item.htmlId)
                                                        }}>
                                                            <i className="fa-solid fa-pencil text-light"></i>
                                                        </span>
                                                    }
                                                    <span
                                                        onClick={() => {
                                                            if (window.confirm('Bu modülü silmek istediğinizden emin misiniz?')) {
                                                                handleDeleteSayfaModul(item.modalId);
                                                            }
                                                        }}
                                                        title="Modülü sil">
                                                        <i className="fa-solid fa-trash"></i>
                                                    </span>
                                                    <span>
                                                        <i className="fa-solid fa-arrows-up-down-left-right"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-100 bg-white">
                                            {item.type === 0 && <ResimYazi sayfaModulId={item.sayfaModulId} />}
                                            {item.type === 1 && <Slider data={item} />}
                                            {item.type === 2 && <Parallax sayfaModulId={item.sayfaModulId} />}
                                            {item.type === 3 && <Gallery sayfaModulId={item.sayfaModulId} />}
                                            {item.type === 4 && <Iletisim sayfaModulId={item.sayfaModulId} />}
                                            {item.type === 5 && <Paragraf sayfaModulId={item.sayfaModulId} />}
                                            {item.type === 6 && <Html sayfaModulId={item.sayfaModulId} />}
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                        <div className="col-2">
                            <ul className="list-group">
                                {moduller.map((item) => (
                                    <li className="list-group-item" key={item.id} style={{ display: 'flex', justifyContent: 'space-between' }} >
                                        {item.adi}
                                        <span onClick={() => handleAddModule(item.id)} className="text-right">
                                            <i className="fa-solid fa-plus"></i>
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
                {/* Sayfa Ekleme Modal */}
                <Modal show={showAddModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Yeni Sayfa Ekle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedSayfa && (
                            <form>
                                <div className="mb-3">
                                    <label className="form-label">Adı:</label>
                                    <input type="text" className="form-control" name="adi" value={selectedSayfa.adi || ''} onChange={handleChange} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Title:</label>
                                    <input type="text" className="form-control" name="title" value={selectedSayfa.title || ''} onChange={handleChange} />
                                    <input type="hidden" className="form-control" name="url" value={selectedSayfa.url || ''} onChange={handleChange} />
                                </div>

                            </form>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Vazgeç</Button>
                        <Button variant="primary" onClick={handleSave}>Kaydet</Button>
                    </Modal.Footer>
                </Modal>
                {/* Sayfa Düzenleme Modal */}
                <Modal show={showEditModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Sayfa Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedSayfa && (
                            <form>
                                <div className="mb-3">
                                    <label className="form-label">Adı:</label>
                                    <input type="text" className="form-control" name="adi" value={selectedSayfa.adi || ''} onChange={handleChange} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Title:</label>
                                    <input type="text" className="form-control" name="title" value={selectedSayfa.title || ''} onChange={handleChange} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Url:</label>
                                    <input type="text" className="form-control" name="url" value={selectedSayfa.url || ''} onChange={handleChange} />
                                </div>
                                <div className="form-check form-switch mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        checked={selectedSayfa.aktif}
                                        onChange={(e) => setSelectedSayfa({ ...selectedSayfa, aktif: e.target.checked })}
                                    />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Durum</label>
                                </div>
                            </form>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Vazgeç</Button>
                        <Button variant="primary" onClick={handleSave}>Kaydet</Button>
                    </Modal.Footer>
                </Modal>
                {/* Yazı Resim Düzenleme Modal */}
                <Modal show={showImageTextModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Resim Yazı Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {imageTextData && (
                            <form>
                                <div className="mb-3">
                                    <label className="form-label">Başlık:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="baslik"
                                        value={imageTextData.baslik || ''}
                                        onChange={(e) => setImageTextData({ ...imageTextData, baslik: e.target.value })}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Açıklama:</label>
                                    <input
                                        className="form-control"
                                        name="icerik"
                                        value={imageTextData.icerik || ''}
                                        onChange={(e) => setImageTextData({ ...imageTextData, icerik: e.target.value })}
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Resim Yükle:</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        accept="image/*"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onloadend = () => {
                                                    setImageTextData({ ...imageTextData, ResimFile: file, imagePreview: reader.result });
                                                };
                                                reader.readAsDataURL(file);
                                            }
                                        }}
                                    />
                                </div>
                                {(imageTextData.imagePreview || imageTextData.existingImageUrl) && (
                                    <div className="mb-3">
                                        <label className="form-label">Yüklenen Resim:</label>
                                        <img
                                            src={imageTextData.imagePreview || imageTextData.existingImageUrl}
                                            alt="Yüklenen Resim"
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                    </div>
                                )}
                                <div className="form-check form-switch mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        checked={imageTextData.durum}
                                        onChange={(e) => setImageTextData({ ...imageTextData, durum: e.target.checked })}
                                    />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Durum</label>
                                </div>
                            </form>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Vazgeç</Button>
                        <Button variant="primary" onClick={() => handleUpdateImageText(imageTextData)}>
                            Güncelle
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Gallery Düzenleme Modal */}
                <Modal show={showGalleryModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title><h4 className="modal-title fs-5" id="staticBackdropLabel">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link text-dark ${activeTab === 'gallery' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('gallery')}
                                        type="button"
                                        role="tab">
                                        Galeri
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link text-dark ${activeTab === 'images' ? 'active' : ''}`}
                                        onClick={() => {
                                            setActiveTab('images');
                                            fetchGalleryImages(GalleryData.id); // galleryImageId burada geçilir
                                        }}
                                        type="button"
                                        role="tab">
                                        Resimler
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link text-dark ${activeTab === 'add' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('add')}
                                        type="button"
                                        role="tab">
                                        Yeni Ekle
                                    </button>
                                </li>
                            </ul>
                        </h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {GalleryData && (
                            <>
                                {activeTab === 'gallery' && (
                                    <div id="gallery-tab-pane-17" role="tabpanel" aria-labelledby="home-tab">
                                        <form>
                                            <div className="mb-3">
                                                <label className="form-label">Başlık:</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="baslik"
                                                    value={GalleryData.baslik || ''}
                                                    onChange={(e) => setGalleryData({ ...GalleryData, baslik: e.target.value })}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Açıklama:</label>
                                                <textarea
                                                    className="form-control"
                                                    name="icerik"
                                                    value={GalleryData.icerik || ''}
                                                    onChange={(e) => setGalleryData({ ...GalleryData, icerik: e.target.value })}
                                                />
                                            </div>
                                            <div className="form-check form-switch mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckChecked"
                                                    checked={GalleryData.durum}
                                                    onChange={(e) => setGalleryData({ ...GalleryData, durum: e.target.checked })}
                                                />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Durum</label>
                                            </div>
                                        </form>
                                    </div>
                                )}
                                {activeTab === 'images' && (
                                    <div className='gallery-image-tab' id="images-tab-pane-17" role="tabpanel" aria-labelledby="images-tab">
                                        {isLoading ? (
                                            <div className="d-flex justify-content-center">
                                                <Spinner animation="border" variant="primary" />
                                            </div>
                                        ) : galleryImages.length > 0 ? (
                                            <div className="row">
                                                {galleryImages.map((image, index) => (
                                                    <div key={image.id} className="col-md-6 mb-3">
                                                        <div className='row'>
                                                            <div className='col-md-3'>
                                                                {image.resim && image.resim.resimUrl && (image.resim.resimUrl.endsWith('.mp4') || image.resim.resimUrl.endsWith('.avi')) ? (
                                                                    <video width="100%" controls>
                                                                        <source src={`/images/${image.resim.resimUrl}`} type="video/mp4" />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                ) : image.resim ? (
                                                                    <img
                                                                        src={`/images/${image.resim.resimUrl}`}
                                                                        alt={image.Baslik}
                                                                        className="img-fluid"
                                                                        onError={(e) => {
                                                                            e.target.onerror = null;
                                                                            e.target.src = "default-image-url.jpg"; // Varsayılan resim URL'si
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <p>Geçersiz resim URL'si.</p>
                                                                )}
                                                            </div>
                                                            <div className='col-md-3'>
                                                                Resim Yukleme
                                                            </div>
                                                            <div className='col-md-3'>
                                                                Detay Butonu
                                                            </div>
                                                            <div className='col-md-3'>
                                                                Sil Butonu
                                                            </div>
                                                            <div className='image-details' id={`image-details-${image.id}`}>
                                                                <div className='image-info-group'>
                                                                    <div className='row'>
                                                                        <div className='col-md-12 mb-3'>
                                                                            <span className='modal-content-title'>Başlık</span>
                                                                            <input
                                                                                type='text'
                                                                                name='baslik'
                                                                                className='form-control'
                                                                                value={image.baslik || ''} // Her bir resmin baslik değerine erişiliyor
                                                                                onChange={(e) => {
                                                                                    const updatedImages = [...galleryImages];
                                                                                    updatedImages[index].baslik = e.target.value;
                                                                                    setGalleryImages(updatedImages); // Değişiklik set ediliyor
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className='col-md-12 mb-3'>
                                                                            <span className='modal-content-title'>İçerik</span>
                                                                            <input
                                                                                type='text'
                                                                                name='icerik'
                                                                                className='form-control'
                                                                                value={image.icerik || ''}
                                                                                onChange={(e) => {
                                                                                    const updatedImages = [...galleryImages];
                                                                                    updatedImages[index].icerik = e.target.value;
                                                                                    setGalleryImages(updatedImages);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-5 mb-3">
                                                                            <span className="modal-content-title">Link (Url)</span>
                                                                            <input type="text" name="url" className="form-control" value={image.url || ''}
                                                                                onChange={(e) => {
                                                                                    const updatedImages = [...galleryImages];
                                                                                    updatedImages[index].url = e.target.value;
                                                                                    setGalleryImages(updatedImages);
                                                                                }} />
                                                                        </div>
                                                                        <div className="col-md-5 mb-3">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <p>Resim bulunmamaktadır.</p>
                                        )}
                                    </div>
                                )}

                                {activeTab === 'add' && (
                                    <div id="images-tab-pane-17" role="tabpanel" aria-labelledby="add-tab">
                                        <form>
                                            <div className="col-md-4 mb-3">
                                                <span className="modal-content-title">Görsel / Video</span>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    name="ResimUrl"
                                                    multiple
                                                    accept="image/jpeg, image/png, image/gif, video/mp4, video/avi"
                                                    onChange={handleFileChange}
                                                />

                                                <input
                                                    type="hidden"
                                                    className="form-control"
                                                    name="ModulGalleryId"
                                                    value={galleryImage.ModulGalleryId || ''}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                )}
                            </>
                        )}
                    </Modal.Body>
                    {activeTab === 'gallery' && (
                        <div id="gallery-tab-pane-17" role="tabpanel" aria-labelledby="home-tab">
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>Vazgeç</Button>
                                <Button variant="primary" type="button" onClick={() => handleUpdateGallery(GalleryData)}>
                                    Güncelle
                                </Button>
                            </Modal.Footer>
                        </div>
                    )}
                    {activeTab === 'images' && (
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>Vazgeç</Button>
                            <Button variant="primary" type="button" onClick={updateGalleryImages}>
                                Güncelle-1
                            </Button>
                        </Modal.Footer>
                    )}
                    {activeTab === 'add' && (
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>Vazgeç</Button>
                            <Button
                                variant="primary"
                                type="button"
                                onClick={handleImageUpload}
                                disabled={files.length === 0 || isLoading}
                            >
                                {isLoading ? 'Yükleniyor...' : 'Yükle'}
                            </Button>
                        </Modal.Footer>
                    )}
                </Modal>
                {/* Parallax Düzenleme Modal */}
                <Modal show={showParallaxModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Parallax Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {ParallaxData && (
                            <form>
                                <div className="mb-3">
                                    <label className="form-label">Başlık:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="baslik"
                                        value={ParallaxData.baslik || ''}
                                        onChange={(e) => setParallaxData({ ...ParallaxData, baslik: e.target.value })}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Açıklama:</label>
                                    <textarea
                                        className="form-control"
                                        name="icerik"
                                        value={ParallaxData.icerik || ''}
                                        onChange={(e) => setParallaxData({ ...ParallaxData, icerik: e.target.value })}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Resim Yükle:</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        accept="image/*"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onloadend = () => {
                                                    setParallaxData({ ...ParallaxData, ResimFile: file, imagePreview: reader.result });
                                                };
                                                reader.readAsDataURL(file);
                                            }
                                        }}
                                    />
                                </div>
                                {(ParallaxData.imagePreview || ParallaxData.existingImageUrl) && (
                                    <div className="mb-3">
                                        <label className="form-label">Yüklenen Resim:</label>
                                        <img
                                            src={ParallaxData.imagePreview || ParallaxData.existingImageUrl}
                                            alt="Yüklenen Resim"
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                    </div>
                                )}
                                <div className="form-check form-switch mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        checked={ParallaxData.durum}
                                        onChange={(e) => setParallaxData({ ...ParallaxData, durum: e.target.checked })}
                                    />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Durum</label>
                                </div>
                            </form>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Vazgeç</Button>
                        <Button variant="primary" onClick={() => handleUpdateParallax(ParallaxData)}>
                            Güncelle
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* İletişim Düzenleme Modal */}
                <Modal show={showIletisimModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>İletişim Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {IletisimData && (
                            <form>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="mb-3">
                                            <label className="form-label">Başlık:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="baslik"
                                                value={IletisimData.baslik || ''}
                                                onChange={(e) => setIletisimData({ ...IletisimData, baslik: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="mb-3">
                                            <label className="form-label">Form Başlık:</label>
                                            <input
                                                className="form-control"
                                                name="formBaslik"
                                                value={IletisimData.formBaslik || ''}
                                                onChange={(e) => setIletisimData({ ...IletisimData, formBaslik: e.target.value })}
                                            />
                                        </div></div>
                                    <div className='col-md-6'>
                                        <div className="mb-3">
                                            <label className="form-label">Icerik:</label>
                                            <textarea
                                                className="form-control"
                                                name="icerik"
                                                value={IletisimData.icerik || ''}
                                                onChange={(e) => setIletisimData({ ...IletisimData, icerik: e.target.value })}
                                            />
                                        </div></div>
                                    <div className='col-md-6'>
                                        <div className="mb-3">
                                            <label className="form-label">Form Icerik:</label>
                                            <textarea
                                                className="form-control"
                                                name="formIcerik"
                                                value={IletisimData.formIcerik || ''}
                                                onChange={(e) => setIletisimData({ ...IletisimData, formIcerik: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-check form-switch mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        checked={IletisimData.durum}
                                        onChange={(e) => setIletisimData({ ...IletisimData, durum: e.target.checked })}
                                    />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Durum</label>
                                </div>
                            </form>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Vazgeç</Button>
                        <Button variant="primary" onClick={() => handleUpdateIletisim(IletisimData)}>
                            Güncelle
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Paragraf Düzenleme Modal */}
                <Modal show={showParagrafModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Paragraf Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {ParagrafData && (
                            <form>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="mb-3">
                                            <label className="form-label">Başlık:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="baslik"
                                                value={ParagrafData.baslik || ''}
                                                onChange={(e) => setParagrafData({ ...ParagrafData, baslik: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className="mb-3">
                                            <label className="form-label">Icerik:</label>
                                            <textarea
                                                className="form-control"
                                                name="icerik"
                                                value={ParagrafData.icerik || ''}
                                                onChange={(e) => setParagrafData({ ...ParagrafData, icerik: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-check form-switch mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        checked={ParagrafData.durum}
                                        onChange={(e) => setParagrafData({ ...ParagrafData, durum: e.target.checked })}
                                    />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Durum</label>
                                </div>
                            </form>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Vazgeç</Button>
                        <Button variant="primary" onClick={() => handleUpdateParagraf(ParagrafData)}>
                            Güncelle
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Html Düzenleme Modal */}
                <Modal show={showHtmlModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Html Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {HtmlData && (
                            <form>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="mb-3">
                                            <label className="form-label">Icerik:</label>
                                            <textarea
                                                className="form-control"
                                                name="icerik"
                                                value={HtmlData.icerik || ''}
                                                onChange={(e) => setHtmlData({ ...HtmlData, icerik: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-check form-switch mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        checked={HtmlData.durum}
                                        onChange={(e) => setHtmlData({ ...HtmlData, durum: e.target.checked })}
                                    />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Durum</label>
                                </div>
                            </form>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Vazgeç</Button>
                        <Button variant="primary" onClick={() => handleUpdateHtml(HtmlData)}>
                            Güncelle
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};
export default SayfaList;
