import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import ContactForm from './ContactForm';

const Page = ({ defaultPage = 'anasayfa' }) => {
    const { urlName } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [page, setPage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [groupedModules, setGroupedModules] = useState({});

    const backendUrl = 'https://localhost:7184';

    useEffect(() => {
        const fetchPageData = async () => {
            try {
                const fetchUrl = urlName ? `${backendUrl}/api/page/${urlName}` : `${backendUrl}/api/page/${defaultPage}`;
                const response = await axios.get(fetchUrl);
                console.log('Page Data:', response.data);
                setPage(response.data);
            } catch (error) {
                console.error('Error fetching page data:', error.response ? error.response.data : error.message);
                const errorMessage = error.response ? JSON.stringify(error.response.data) : error.message;
                setError(errorMessage);
            } finally {
                setLoading(false);
            }
        };

        fetchPageData();
    }, [urlName, defaultPage]);

    useEffect(() => {
        if (location.pathname === `/${defaultPage}`) {
            navigate('/', { replace: true });
        }
    }, [location.pathname, navigate, defaultPage]);

    useEffect(() => {
        if (page) {
            const grouped = page.moduller.reduce((groups, module) => {
                const { modulAdi } = module;
                if (!groups[modulAdi]) {
                    groups[modulAdi] = [];
                }
                groups[modulAdi].push(module);
                return groups;
            }, {});

            setGroupedModules(grouped);
        }
    }, [page]);

    const sortedModules = Object.values(groupedModules).flat().sort((a, b) => a.id - b.id);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!page) {
        return <div>No page data available</div>;
    }

    return (
        <div className="body-content">
            {sortedModules.map(module => (
                module.modulAdi === "Yazı Resim" ? (
                    <div
                        className="pageDetailContent textImg"
                        id={`page-detail-section-${module.id}`}
                        key={module.id}
                    >
                        <div className="container">
                            <div className="row">
                                <div
                                    className="col-md-6 rexa-content content-left aos-init aos-animate"
                                    data-aos="fade-right"
                                    data-aos-duration="900"
                                >
                                    <h1 className="weta-icerik-tag">{module.modulYaziResimListesi?.baslik || 'No Title'}</h1>
                                    <div className="weta-description">
                                        <p>{module.modulYaziResimListesi?.icerik || 'No Content'}</p>
                                    </div>
                                </div>
                                {module.modulYaziResimListesi?.resimAdi && (
                                    <div
                                        className="col-md-6 rexa-gorseller gorsel-right aos-init aos-animate"
                                        data-aos="fade-left"
                                        data-aos-duration="900"
                                    >
                                        <div className="rg-wrapper">
                                            <div className="rgw">
                                                <img
                                                    src={`/images/${module.modulYaziResimListesi.resimAdi}`}
                                                    title={module.modulYaziResimListesi.baslik || 'Image'}
                                                    style={{ maxWidth: '100%', height: 'auto' }}
                                                    alt={module.modulYaziResimListesi.baslik || 'Image'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ) : module.modulAdi === "Gallery" ? (
                    <div className={`pageDetailContent gallery-section-${module.id}`} key={`gallery-${module.id}`}>
                        <div className="container">
                            <h2 className="weta-icerik-tag">{module.modulGalleryListesi.baslik || 'Gallery Title'}</h2>
                            <p>{module.modulGalleryListesi.icerik || 'Gallery Description'}</p>
                            <div className="gallery-wrapper">
                                <div className="row">
                                    {module.modulGalleryListesi.resimler && module.modulGalleryListesi.resimler.map(galleryItem => (
                                        <div className="col-md-3 custom-col image aos-init aos-animate" data-aos="fade-up" data-aos-duration="900" key={galleryItem.id}>
                                            <div className="img-wrapper">
                                                <div className="gallery-img">
                                                    <img src={`/images/${galleryItem.resimAdi}`} alt={galleryItem.baslik} style={{ maxWidth: '100%', height: 'auto' }} />
                                                </div>
                                                <div className="gallery-info">
                                                    <div className="img-title">
                                                        <h3 className="weta-icerik-tag">{galleryItem.baslik || 'No Title'}</h3>
                                                    </div>
                                                    <div className="img-description">
                                                        <p>{galleryItem.icerik || 'No Description'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : module.modulAdi === "Parallax" ? (
                    <div
                        className="pageDetailContent parallax-section"
                        id={`parallax-${module.id}`}
                        key={module.id}
                        style={{
                            backgroundColor: '#ffffff', backgroundImage: module.modulParallaxListesi && module.modulParallaxListesi.resimAdi
                                ? `url(/images/${module.modulParallaxListesi.resimAdi})`
                                : `url(default-image-url.jpg)`
                        }}
                    >
                        <div className='container text-start'>
                            <div className='rg-wrapper'>
                                <div className='rgw'>
                                    <div className="parallax-title">
                                        <h2 className="weta-parallax-tag">{module.modulParallaxListesi.baslik || 'Parallax Title'}</h2>
                                    </div>
                                    <div className="parallax-description">
                                        <p>{module.modulParallaxListesi.icerik || 'Parallax Description'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : module.modulAdi === "Iletisim" ? (
                    <div
                        className="pageDetailContent form-section"
                        id={`contactForm-${module.id}`}
                        key={module.id}>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6 rexa-content'>
                                    <h3 className="weta-icerik-tag">{module.modulIletisimListesi.baslik || 'x'}</h3>
                                    <div className="weta-description">
                                        <p>{module.modulIletisimListesi.icerik || 'x'}</p>
                                    </div>
                                </div>
                                <div className='col-md-6 contact-form'>
                                    <h3 className="weta-icerik-tag">{module.modulIletisimListesi.formBaslik || 'x'}</h3>
                                    <div className='form-description'>
                                        <p>{module.modulIletisimListesi.formIcerik || 'x'}</p>
                                    </div>
                                    <ContactForm />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : module.modulAdi === "Paragraf" ? (
                    <div
                        className="pageDetailContent paragraphSection"
                        id={`paragraph-section-${module.id}`}
                        key={module.id}>
                        <div className='container'>
                            <div className='col-md-12'>
                                <div className='paragraph-title'>
                                    <h3 className="weta-icerik-tag">{module.modulParagrafListesi.baslik || 'x'}</h3>
                                </div>
                                <div className="weta-description">
                                    <p>{module.modulParagrafListesi.icerik || 'x'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : module.modulAdi === "Html" ? (
                    <div
                        className="pageDetailContent htmlSection"
                        id={`html-section-${module.id}`}
                        key={module.id}>
                        <h3 className="weta-icerik-tag">{module.modulHtmlListesi.icerik || ''}</h3>
                    </div>
                ) : null
            ))}
        </div>
    );
};

export default Page;
